import { v4 as uuidv4 } from 'uuid';

import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BreadcrumbItem } from '@do/app-common';
import { IconButtonComponent } from '@do/app-ui-kit';

import { BroadcastChannelEventType } from './constants';

@Component({
  standalone: true,
  selector: 'do-iframe-wrapper',
  imports: [CommonModule, IconButtonComponent],
  template: `
    <iframe
      [src]="sanitizedUrl"
      width="100%"
      height="100%"
      frameborder="0"
      style="border:0"
      allowfullscreen
      onload="this.contentWindow.focus()"
    ></iframe>
  `,
  styles: [
    `
      :host {
        height: 100%;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
      }
    `,
  ],
})
export class IFrameWrapperComponent implements OnInit, OnChanges, OnDestroy {
  @Input() entity!: string;

  @Input()
  mode!: string;

  @Input() id?: string;

  @Input()
  queryParams?: string;

  @Input()
  modal = true;

  @Output()
  saved = new EventEmitter<any>();

  @Output()
  deleted = new EventEmitter<any>();

  @Output()
  selected = new EventEmitter<any>();

  @Output()
  loaded = new EventEmitter<any>();

  @Output()
  breadcrumbChanged = new EventEmitter<BreadcrumbItem[]>();

  sanitizedUrl!: SafeResourceUrl;
  url!: string;

  private channelName?: string;

  private bc?: BroadcastChannel;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (!this.entity || !this.mode) throw new Error('mandatory data missing');
    this.channelName = uuidv4();
    this.loadIFrame(this.id);
    this.bc = new BroadcastChannel(this.channelName);
    this.bc.onmessage = (ev: {
      data: {
        type: BroadcastChannelEventType;
        item: any;
        entityType: string | null;
      };
    }) => {
      switch (ev.data.type) {
        case BroadcastChannelEventType.Select:
          this.bc?.postMessage({ type: BroadcastChannelEventType.Ack });
          this.selected.emit(ev.data.item);
          break;

        case BroadcastChannelEventType.Delete:
          this.bc?.postMessage({ type: BroadcastChannelEventType.Ack });
          this.deleted.emit(ev.data.item);
          break;

        case BroadcastChannelEventType.Save:
          this.bc?.postMessage({ type: BroadcastChannelEventType.Ack });
          this.saved.emit(ev.data.item);
          break;

        case BroadcastChannelEventType.Breadcrumb:
          this.bc?.postMessage({ type: BroadcastChannelEventType.Ack });
          this.breadcrumbChanged.emit(ev.data.item);
          break;
      }
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes['id'];
    // if (
    //   !change.isFirstChange() &&
    //   change.currentValue != change.previousValue
    // ) {
    //   this.loadIFrame();
    // }
  }

  ngOnDestroy(): void {
    this.closeChannel();
  }

  closeChannel() {
    this.bc?.close();
  }

  loadIFrame(id: string | undefined) {
    this.url =
      window.location.origin +
      (this.modal ? '/modal/' : '/iframe/') +
      this.entity.replace('_', '-') +
      (id != null ? '/' + id : '') +
      `?${this.mode}=${this.channelName}${
        this.queryParams ? '&' + this.queryParams : ''
      }`;

    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
}
