// export const ADD_MODE_PARAM = 'addMode';
export const VIEW_MODE_PARAM = 'viewMode';
export const FIND_MODE_PARAM = 'findMode';
export const EDIT_MODE_PARAM = 'editMode';
export const MODAL_LIST_MODE_PARAM = 'modalListMode';

export enum BroadcastChannelEventType {
  Select = 'select',
  Save = 'save',
  Delete = 'delete',
  Load = 'load',
  Navigate = 'navigate',
  Breadcrumb = 'breadcrumb',
  Ack = 'ack',
}
